import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { graphql, navigate } from 'gatsby'
import useLanguage from '../../hooks/use-language'
import KommitteerHero from './kommitteer-hero'
import { getNode, getNodesTest } from '../../helpers/graph-helper'
import KommitteeDialog from './kommittee-dialog/index'
import KommitteerTeasers from './kommitteer-teasers'
import { Box, Typography } from '@material-ui/core'
import { H3 } from '../../components/common/fonts'

const Kommitteer = ({ data, pageContext, location }) => {
  const [selectedCommittee, setSelectedCommittee] = useState()
  useEffect(() => {
    if (selectedCommittee) {
      window.history.pushState(
        undefined,
        selectedCommittee.name[0].text,
        `?committee=${selectedCommittee.name[0].text}`
      )
    } else {
      window.history.pushState(
        undefined,
        'kommitteer',
        '/kommitteer'
      )
    }
  }, [selectedCommittee])
  useEffect(() => {
    let committee
    if (location.search) {
      const query = location.search.split('?committee=')[1]
      committee = committees.find(
        committee => encodeURI(committee.name[0].text) === query
      )
    }
    setSelectedCommittee(committee)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const close = () => {
    setSelectedCommittee()
  }
  const language = useLanguage()
  let node = getNode(language.locale, data, 'allCommittee_pages')
  let committees = getNodesTest(language.locale, pageContext.committees)
  let groupedCommittees = {
    ' ': [],
    'Kommittéer': [],
    'Övrigt': []
  }
  for (const committee of committees) {
    if (!committee.isstyrelsen) {
      let group = 'Övrigt'
      console.log(committee.group === 'First')
      if (committee.group === 'First') {
        group = ' '
      } else if (committee.group && groupedCommittees[group]) {
        group = committee.group
      }
      console.log(group)
      groupedCommittees[group].push(committee)
    }
  }
  return (
    <Layout>
      <SEO title='Kommittéer' />
      <KommitteerHero
        url={node.hero_background_image.url}
        title={node.hero_title}
      />
      <Box style={{ paddingBottom: '50px' }}>
        {Object.keys(groupedCommittees).map(title => groupedCommittees[title].length ? <Box>
          <Box width="100%" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-25px', marginTop: '25px' }}><H3>{title}</H3></Box>
          <KommitteerTeasers
            committees={groupedCommittees[title].sort(
              (a, b) => (a.sort_order || 10000) - (b.sort_order || 10000)
            )}
            handleClick={setSelectedCommittee}
          />
        </Box> : null
        )}
      </Box>
      <KommitteeDialog committee={selectedCommittee} close={close} />
    </Layout>
  )
}

export default Kommitteer

export const query = graphql`
  {
    prismic {
      allCommittee_pages {
        edges {
          node {
            _meta {
              lang
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
          }
        }
      }
    }
  }
`
