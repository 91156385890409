import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR, DEFAULT_COLOR_RGBA } from '../../helpers/colors'
import CustomRichText from '../custom-rich-text'
import useTranslation from '../../hooks/use-translation'
import { breakpoints } from '../../helpers/media'

const Wrapper = styled.div(
  ({ invert }) => `
  height: 375px;
  width: 375px;
  margin: 30px;
  position: relative;
  :hover {
    cursor: pointer;
    ::after {
      position: absolute;
      top: 25px;
      right: ${invert ? '390px' : ''};
      left: ${invert ? '' : '382px'};
      margin-right: -10px;
      content: '';
      display: block;
      height: 90%;
      width: 2px;
      background: ${DEFAULT_COLOR};
      transition: width 0.3s;
      transition-delay: 0.1s;
      z-index: 10000;
    }
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: 250px;
    width: 250px;
  }
`
)

const ImgWrapper = styled.div`
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 85%;
`

const ReadMore = styled.a`
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 100px;
  overflow: hidden;
  border: none;
  margin-top: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  background: ${DEFAULT_COLOR};
  transition: height 0.3s ease-in-out;
  ${Wrapper}:hover & {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background: ${DEFAULT_COLOR_RGBA(0.85)};
    cursor: pointer;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const TextWrapper = styled.div(
  ({ invert }) => `
  position: absolute;
  top: 0;
  left: ${invert ? '-430px' : '430px'};
  width: fit-content;
  min-width: 430px;
  background-color: white;
  height: 100%;
  z-index: 10000;
  overflow: hidden;
  opacity: 0;
  padding: 20px;
  display: none;
  transition: all 0.2s ease-in-out;
  & p {
    font-size: 16px;
    letter-spacing: 0px;
    color: #4c4d4fe5;
    opacity: 1;
  }
  & h1, h2, h4, h5, h6 {
    font-size: 18px;
  }
  & a {
    color: black;
  }
  pointer-events: none;
  ${Wrapper}:hover & {
    opacity: 1;
    cursor: pointer;
    pointer-events: default;
  }
  @media only screen and (min-width: ${breakpoints.lg}px) {
    display: block;
  }
  iframe {
    display: none;
  }
`
)

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 30px important!;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  text-shadow: 0px 2px 3px #0000001f;
  opacity: 1;
`
const ExpandableTeaser = ({ logo, name, shortText, handleClick }) => {
  const [invert, setInvert] = useState(false)
  const wrapperRef = useRef()

  const t = useTranslation()

  const handleMouseEnter = () => {
    const curr = wrapperRef.current
    const margin =
      curr.parentElement.offsetWidth -
      (curr.offsetLeft - curr.parentElement.offsetLeft) -
      curr.offsetWidth
    setInvert(margin < 350)
  }
  return (
    <Wrapper
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      invert={invert}
      onClick={handleClick}
    >
      <ImgWrapper>
        {logo && (
          <Img
            fluid={logo}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
        <ReadMore onClick={handleClick}>{t`Read more`}</ReadMore>
      </ImgWrapper>
      <TextWrapper invert={invert}>
        <Title>{RichText.asText(name)}</Title>
        <CustomRichText richText={shortText}/>
      </TextWrapper>
    </Wrapper>
  )
}

export default ExpandableTeaser
