import React from 'react'
import styled from 'styled-components'
import ExpandableTeaser from '../../../components/expandable-teaser'
import InfoRow from '../../../components/info-row'
import useTranslation from '../../../hooks/use-translation'

const Wrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InnerWrapper = styled.div`
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const KommitteerTeasers = ({ committees, handleClick }) => {
  const t = useTranslation()
  return (
    <Wrapper>
      <InnerWrapper>
        {committees.map((committee, i) => (
          <InfoRow
            small
            maxHeight="200px"
            key={i}
            image={committee.logo_imageSharp &&
              committee.logo_imageSharp.childImageSharp.fluid}
            title={committee.name}
            text={committee.information}
            action={{
              title: t('Read more'),
              handleClick: () => handleClick(committee)
            }}
          />
        ))}
      </InnerWrapper>
    </Wrapper>
  )
}

export default KommitteerTeasers
