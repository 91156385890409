import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import ImageTeaser from '../../../components/image-teaser'
import DefaultKommittee from './default-kommittee'
import Members from './members'
import GalleryScroller from '../../../components/gallery-scroller'
import KommitteeContent from '../../../components/kommittee-content'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  height: auto;
`

const KommitteeDialog = ({ committee, close }) => {
  const renderSlice = (slice, i) => {
    switch (slice.type) {
      case 'image_gallery':
        return (
          <GalleryScroller
            images={slice.fields.map(
              field => field.gallery_imageSharp.childImageSharp
            )}
          />
        )
      case 'default_committee_view':
        return (
          <DefaultKommittee
            key={i}
            introduction={slice.primary.introduction}
            contacts={slice.fields}
            image={
              slice.primary.committee_imageSharp &&
              slice.primary.committee_imageSharp.childImageSharp.fluid
            }
          />
        )
      case 'members':
        return (
          <Members
            key={i}
            title={slice.primary.members_title}
            members={slice.fields}
          />
        )
      default:
        return <h1 key={i}>SLICE TYPE: {slice.type} not found</h1>
    }
  }
  return (
    <Dialog
      open={!!committee}
      onClose={close}
      maxWidth='xl'
      fullWidth
      TransitionComponent={Transition}
    >
      <KommitteeContent committee={committee} />
    </Dialog>
  )
}

export default KommitteeDialog
